import axios from "axios";

/**
* Returns data of passed URL link
* 
* @async
* @param {string} url - URL to get data from
*/
export const getURLData = async (url) => {
    try {
        const response = await axios.get(url);
        return response.data;
    } catch (e) {
        console.error("getURLData: Error getting data from URL ", url, "\n", e)
        return null;
    }

}

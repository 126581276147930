import React from "react";
import Home from './component/Home'
import Header from './component/Header'
import Footer from './component/Footer'
import Imagedetail from './component/Imagedetail'
import { BrowserRouter, Routes, Route, useParams,  } from 'react-router-dom';
import './App.css';
import About from './component/About';
import Contact from './component/Contact';
import License from './component/License';


function App() {

  /**
  * Navigates to image based on "name" route parameter
  */
  const ImageRedirect = () => {
    const { name } = useParams();
    window.location.assign(`${window.location.origin}/Json_images_original/${name}.png`);
    return null;
  }

  return (
    <div className="App">
      <Header />
      <BrowserRouter>
        <Routes>
        <Route path="/" element={ <Home />} />
        <Route path="/pages/contact" element={ <Contact />} />
        <Route path="/pages/about" element={ <About />} />
        <Route path="/pages/license" element={ <License />} />
        <Route path="/:name" element={<Imagedetail />} />
        <Route path="/:name.png" element={<ImageRedirect />}
        />
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;

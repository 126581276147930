import React from 'react'
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { SocialIcon } from 'react-social-icons';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme();

export default function Contact() {

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="sm">
                <CssBaseline />
                <div id="icons">
                    <SocialIcon url="https://twitter.com/thisdaynft" />                
                </div>
                <div id="icons">
                    <SocialIcon url="mailto:info@thisdaynft.com" />
                </div>
            </Container>
        </ThemeProvider>
    );
}
import React, { useState, useEffect, useCallback } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { GridSpinner } from "react-spinners-kit";
import { getURLData } from "../utils/getURLData";
import InfiniteScroll from "react-infinite-scroll-component";
import DatePicker from "react-datepicker"

import "react-datepicker/dist/react-datepicker.css";

function PricingContent() {
  // Number of images to load when user scrolls to threshold
  const IMAGES_PER_LOAD = 20;

  const [listImages, setListImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true); // True if more images to load, false if not
  const [imageQueue, setImageQueue] = useState([]); // Contains fetched image data
  const [imageDataJson, setImageDataJson] = useState([]);
  const [noImageResults, setNoImageResults] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [earliestDate, setEarliestDate] = useState(null);
  const [latestDate, setLatestDate] = useState(null);
  /**
   * Returns parsed image object with more information
   *
   * @returns Parsed image object
   */
  const parseImageObject = async (imageObject) => {
    try {
      // Set path, image, date
      var external_url = imageObject.external_url;
      imageObject.path = external_url.slice(external_url.lastIndexOf("/") + 1);
      imageObject.image =
        "Json_images_low/thisday_" +
        imageObject.external_url.split("/")[3].replace("thisday_", "") +
        "_thumb.png";
      imageObject.date = parseUnderscoredDate(imageObject.date);
    } catch (e) {
      console.error(
        "parseImageObject: Error parsing for object: ",
        imageObject,
        " error: ",
        e
      );
      imageObject = null;
    }

    return imageObject;
  };

  /**
   * Returns Date object for passed date String which must be in format
   * thisday_mm_dd_yyyy or mm_dd_yyyyy
   *
   * @returns Date
   */
  const parseUnderscoredDate = (dateString) => {
    let dateObject = new Date(-1);
    if (dateString) {
      dateString.replace("thisday_", "");
      let monthIndex = parseInt(dateString.split("_")[0]) - 1;
      let day = parseInt(dateString.split("_")[1]);
      let year = parseInt(dateString.split("_")[2]);

      // If date parsed correctly, return date
      if (!isNaN(monthIndex) && !isNaN(day) && !isNaN(year)) {
        dateObject = new Date(year, monthIndex, day);
      } else {
        // Else, warn and return placeholder date
        console.warn(
          "parseUnderscoredDate: Failed to parse date for passed dateString: " +
          dateString
        );
      }
    }
    return dateObject;
  };

  /**
   * Fetches IMAGES_PER_LOAD amount of images, adds to listImages to be displayed
   *
   * @async
   */
  async function fetchImages(dataJson = imageQueue.slice(), isSearch) {
    let imageObjectArray = [];

    if (dataJson.length > 0) {
      // Get 20 valid images, add to unresolved promises array
      for (let i = 0; i < IMAGES_PER_LOAD && dataJson.length > 0; i++) {
        // If processing last element, set hasMore to false
        if (dataJson.length === 1) {
          setHasMore(false);
        }

        let imageObject = null;

        if (dataJson[0] && dataJson[0].image_hash) {
          // Get image hash file
          imageObject = await getURLData(
            `${window.location.origin}/JsonData/${dataJson[0].image_hash}`
          );
          imageObject.date = dataJson[0].date
        }

        // If image object name doesn't match data json name, error and do not push image
        if (imageObject?.name !== dataJson[0].name) {
          console.error(
            "fetchImages: Name for image object does not match dataJson object, hash name possibly incorrect?",
            " imageObject: ",
            imageObject,
            " dataJson[0]: ",
            dataJson[0]
          );
          imageObject = null;
        }

        dataJson.splice(0, 1); // Remove element we just processed (dataJson[0])
        imageObject = await parseImageObject(imageObject); // Parse imageObject

        // Check if image object is a duplicate
        let duplicateObject = imageObjectArray.find(
          (image) =>
            image.image_hash === imageObject?.image_hash ||
            image.name === imageObject?.name ||
            image.date === imageObject?.date
        );

        // If image object parses successfully, and is not a duplicate, push onto imageObjectArray
        if (imageObject != null && duplicateObject == null) {
          imageObjectArray.push(imageObject);
        } else {
          // If duplicate object detected, output error
          if (duplicateObject != null) {
            console.error(
              "fetchImages: Duplicate image detected for ",
              imageObject,
              " matches with ",
              duplicateObject
            );
          }
          // If invalid image, decrement counter since doesn't count as valid image
          i--;
        }
      }

      let newListImages = [];

      // If not a search, include currently listed images to newListImages
      if (!isSearch) {
        newListImages = listImages.slice();
      }

      newListImages = newListImages
        .concat(imageObjectArray) // Concatenate newly processed images to newListImages
        .filter((x) => x); // Filter null values

      setImageQueue(dataJson.slice()); // Update imageDataJson state
      setListImages(newListImages);
    } else {
      console.error("fetchImages: Failed to fetch, no more images in queue");
    }
  }

  useEffect(() => {
    /**
     * Fetches all image data and populates listImages state
     *
     * @async
     */
    const fetchImageDataJson = async () => {
      // Get image data index JSON
      let imageDataIndexJson = await getURLData("/imageDataIndex.json");

      // If index JSON not found, return
      if (!imageDataIndexJson) {
        return;
      }

      // Error-check if imageDataIndex can be parsed as JSON:
      try {
        if (typeof imageDataIndexJson != "string") {
          imageDataIndexJson = JSON.stringify(imageDataIndexJson);
        }
        imageDataIndexJson = JSON.parse(imageDataIndexJson);
      } catch (e) {
        console.error(
          "fetchAllImageData: imageDataIndex cannot be parsed as a JSON: ",
          e
        );
        return;
      }

      console.log("Parsed imageDataIndex: ", imageDataIndexJson);

      // Sort image date arrays by date in ascending order
      imageDataIndexJson.sort((a, b) => {
        let aParsed = parseUnderscoredDate(a.date);
        let bParsed = parseUnderscoredDate(b.date);
        if (aParsed < bParsed) {
          return -1;
        } else {
          return 1;
        }
      });

      setImageDataJson(imageDataIndexJson.slice());
    };
    setIsLoading(true);
    fetchImageDataJson();
  }, []);

  useEffect(() => {
    // If listImages is populated, turn off loader
    if (imageDataJson.length > 0) {
      const earlyDate = parseUnderscoredDate(imageDataJson[imageDataJson.length - 1].date);
      const lateDate = parseUnderscoredDate(imageDataJson[0].date);

      setEarliestDate(lateDate);
      setLatestDate(earlyDate);

      fetchImages(imageDataJson.slice());
    }
  }, [imageDataJson]);

  useEffect(() => {
    // If listImages is populated, turn off loader
    if (listImages.length > 0) {
      setIsLoading(false);
    } else {
      console.log("listImages cleared");
    }
  }, [listImages]);

  /**
   * Filters images based on passed date range
   */
  const onDateRangeChange = (dates) => {
    let [start, end] = dates;
    let datesEmpty = start == null && end == null;

    setStartDate(start);
    setEndDate(end);

    if (datesEmpty) {
      // If clear button pressed or empty search, 
      // Set start and end to earliest/latest date to show all images
      start = earliestDate;
      end = latestDate;
    }

    if (start != null && end != null && imageDataJson.length > 0) {
      // Load images within range
      console.log("Searched: ", start, " to ", end);
      setHasMore(true);
      let filteredJson = imageDataJson.filter((img) =>
        parseUnderscoredDate(img.date) >= start && parseUnderscoredDate(img.date) <= end
      ).sort((a, b) =>
        // If search empty, sort in descending order. Else, ascending
        parseUnderscoredDate(a.date) - parseUnderscoredDate(b.date)
      );
      console.log("Filtered jsons: ", filteredJson);
      if (filteredJson.length > 0) {
        fetchImages(filteredJson.slice(), true);
        setNoImageResults(false);
      } else {
        // If no results found, set no image results flag to true
        setNoImageResults(true);
      }
    }
  };

  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <Container
        disableGutters
        maxWidth="lg"
        component="main"
        sx={{ pt: 8, pb: 6 }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography
              component="h3"
              variant="h3"
              color="text.primary"
              gutterBottom
            ></Typography>
            <Typography variant="h5" color="text.secondary" component="h5">
              Every day is unique. Every day is special. Some are heartbreaking,
              others full of profound happiness. thisday creates a single NFT
              each day. The NFT is a computer generated work of art that
              captures what made each day unique including news from around the
              world, what was popular on social media, weather and much much
              more.
            </Typography>
            <div style={{ marginTop: "25px" }}></div>
            <div style={{ marginTop: "85px" }}></div>
          </Grid>
          <Grid item xs={12} md={6}></Grid>
        </Grid>
      </Container>

      <Container maxWidth="lg" component="main">
        <Toolbar
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
            marginBottom: '10px',
            width: '100%',
          }}
        >
          <div style={{ width: '230px' }}>
            <DatePicker
              selected={startDate}
              onChangeRaw={e => {
                if (e.target.value != null) {
                  // Parse string into dates array
                  const dates = e.target.value.split(' - ').map(date => {
                    const d = new Date(date);
                    return d >= earliestDate && d <= latestDate ? d : null;
                  });
                  if (!dates.includes(null) && dates.length > 1 && dates[0] <= dates[1]) {
                    // If no invalid dates and start <= end date, change date range
                    onDateRangeChange(dates);
                  }
                }
              }}
              onChange={onDateRangeChange}
              startDate={startDate}
              endDate={endDate}
              minDate={earliestDate}
              maxDate={latestDate}
              placeholderText={"Filter by date range"}
              selectsRange
              isClearable
              disabledKeyboardNavigation
            />
          </div>
        </Toolbar>
        <div>
          {isLoading ? (
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                marginTop: "50px",
              }}
            >
              <GridSpinner
                size={30}
                color="#686769"
                loading={true}
              ></GridSpinner>
            </div>
          ) : (
            ""
          )}
          {!noImageResults ? (
            <InfiniteScroll
              dataLength={listImages.length}
              loader={
                !isLoading && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <GridSpinner
                      size={30}
                      color="#686769"
                      loading={true}
                    ></GridSpinner>
                  </div>
                )
              }
              hasMore={hasMore}
              next={() => {
                fetchImages();
              }}
              style={{ overflowX: "hidden" }}
            >
              <div className="row">
                {!isLoading &&
                  listImages
                    .sort((a, b) => {
                      return a.date - b.date;
                    })
                    .map((data, i) => (
                      <div
                        key={i}
                        className="col-lg-4 col-md-6 col-sm-12 d-flex align-items-stretch mb-4"
                      >
                        <Link
                          to={{
                            pathname: `/${data.path}`,
                          }}
                          className="card_1"
                        >
                          <img
                            className="img-fluid"
                            alt={"thisday_thumb"}
                            src={data.image}
                          ></img>
                          <h2>{data.name}</h2>
                        </Link>
                      </div>
                    ))}
              </div>
            </InfiniteScroll>
          ) : (
            "No search results found."
          )}
        </div>
      </Container>
    </React.Fragment>
  );
}

export default function Pricing() {
  return <PricingContent />;
}

import React from 'react';
import Container from '@mui/material/Container';
import history from '../images/history.jpg'

function About() {
  return <div>

  <Container maxWidth="lg">

  <img style={{height:"auto",width: "100%",padding:"35px" }} src={history} alt="historic posters" />
  
  <h3>We love History!
  </h3>

  <p>Every day is unique. Every day is special.  Some are heartbreaking, others
    full of profound happiness.  And one person&apos;s favorite day may be another&apos;s
    worst. 
  </p>

  <p>On average most of us will live around 30,000 days.  During that time  
    days can often blur together but over the course of that time 
    many days standout. It may be the birth of a child, the day you first fell 
    in love, the death of a loved one, or a myriad of other things that
    could of made this day special and unique to you.  Whatever it was, the
    thisday project goal is to capture as many things as possible that occurred
    on a day as a reminder of what made it special. 
     </p>
  
  </Container>
  
  </div>;
}

export default About;

import React, { useState, useEffect } from "react";

import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useLocation } from "react-router-dom";
import { OpenSeaDragonViewer } from "./OpenSeaDragon";
import { getURLData } from "../utils/getURLData";

import axios from "axios";
import * as Web3 from 'web3';
import { OpenSeaPort, Network } from 'opensea-js'
import { OrderSide } from 'opensea-js/lib/types'
import { GridSpinner } from "react-spinners-kit";
import Paper from '@mui/material/Paper';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

// Note: Open Sea API Key is unsafely stored
const OpenSeaApiKey = "281b5ce1492d4fd3834982b2ae05a649";

const provider = new Web3.providers.HttpProvider('https://mainnet.infura.io')
const seaport = new OpenSeaPort(provider, {
    networkName: Network.Main,
    apiKey: OpenSeaApiKey,
})
var MyImageData = {};

export default function RecipeReviewCard(props) {
    const location = useLocation();
    const title = location.pathname.split("/")[1];
    const imageDate = title.replace("thisday_", "");
    const GET_ASSET = "getAsset";
    const GET_ORDERS = "getOrders";

    const [isLoader, setIsLoader] = useState(true);
    const [data, setData] = useState([]); // Data state for image info

    // States for OpenSea information
    const [loadingMessage, setLoadingMessage] = useState("Loading OpenSea info...");
    const [failedToFetch, setFailedToFetch] = useState(false);
    const [openSeaAsset, setOpenSeaAsset] = useState();
    const [listings, setListings] = useState();
    const [orders, setOrders] = useState();
    const [stats, setStats] = useState();
    const [events, setEvents] = useState();
    const [sale, setSale] = useState();

    // How long to wait before retrying failed API call
    const rateLimitDelay = 2000;

    // Max amount of fetch retries before stopping
    const maxFetchRetries = 10;

    // Notification for links to images and OpenSea
    const imageLinkNotification = `thisdayNFT images are very high resolution (22,500 x 22,500) and large (~300MB).
   The image above is a special format that allows quick loading and still shows all the detail
   when you zoom in.  For the original image `
    const openSeaLink = 'Buy this NFT on OpenSea'
    const tokenAddress = '0x8a242e39e1360400581cd58f9c06aaa0d683c560';
    //const tokenAddress = '0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d'; // Test Address
    const openSeaRedirect = 'https://opensea.io/assets/ethereum/' + tokenAddress + '/'
    const descriptionText = 'A Snapshot of thisday in History Preserved'
    const failedToFetchText = 'Failed to Fetch OpenSea Info - Click to Retry'

    useEffect(() => {

        /**
         * Attempts to fetch data from hash JSON for current webpage. 
         * Handles exceptions if hash JSON fails to fetch.
         * Returns true if fetch is successful, false otherwise
         * 
         * @async
         * @returns boolean
         */
        const fetchHashJSONData = async () => {
            // Get image data index JSON
            let imageDataJson = await getURLData('/imageDataIndex.json');

            // If index JSON not found, return
            if (!imageDataJson) {
                return false;
            }

            // Error-check if imageDataJson can be parsed as JSON:
            try {
                if (typeof imageDataJson != 'string') {
                    imageDataJson = JSON.stringify(imageDataJson);
                }
                imageDataJson = JSON.parse(imageDataJson);
            } catch (e) {
                console.error("fetchHashJSONData: imageDataJson cannot be parsed as a JSON: ", e);
                return false;
            }
            
            // Look for image date in imageDataJson
            const jsonElement = imageDataJson.find(image => image.date === imageDate);
            let URLData = null;
            
            if (jsonElement !== null) {
                // Get Json hash file
                URLData = await getURLData(
                    `${window.location.origin}/JsonData/${jsonElement.image_hash}`
                );
                if (URLData !== null) {
                    if (URLData.image_hash) {
                        // If image hash does not start with 0x, prepend 0x
                        if (URLData.image_hash.slice(0, 2) !== "0x") {
                            console.warn("fetchHashJSONData: Object hash does not start with 0x, adding 0x...")
                            URLData.image_hash = "0x" + URLData.image_hash;
                        }
                        // Set hashID used for OpenSea API
                        /* global BigInt */ // This is required for BigInt to work 
                        URLData.hashID = BigInt(URLData.image_hash);
                    } else {
                        console.error("fetchHashJSONData: URL data has no image hash for: ", URLData);
                    }
                } else {
                    console.error("fetchHashJSONData: Failed to fetch URL data for: ", title);
                    URLData = {};
                    URLData.name = "";
                    URLData.description = "";
                    return false;
                }
            } else {
                console.error("fetchHashJSONData: Failed to find JSON element for: ", title);
                return false;
            }

            setData(URLData);
            return true;
        }

        setIsLoader(true);

        // If fetchHashJsonData fails, set fail flag to true
        if(!fetchHashJSONData()) {
            setFailedToFetch(true);
        }
    }, []);

    useEffect(() => {
        /**
         * Attempts to fetch data for passed tokenId from OpenSea API.
         * Updates states based on fetched data. 
         * Retries failed API calls and sets failed flag to true if 
         * too many retries.
         * 
         * @async
         * @param {String} tokenId - tokenId for asset to fetch
         */
        const fetchOpenSeaAPI = async (tokenId) => {

            var failFlag = false; // Var flag required if failedToFetch fails to update

           /**
             * Returns promise for setTimeout of passed ms
             * Usage: 'await delay(1000)' to pause for 1 second
             * 
             * @param {int} ms - Ms for setTimeout
             * @returns Promise - for setTimeout
             */
            const delay = ms => new Promise(res => setTimeout(res, ms));
            
            /**
             * Returns data from fetch based on passed parameters,
             * retries if failed, sets failed flag if enough retries
             * 
             * @param {string} getLink - Link for fetch call, or function type for openSeaJS
             * @param {object} options - Options for fetch call
             * @param {bool} isOpenSeaJS - True if openSeaJS call
             * @returns Promise - for whether or not successful
             */
            const retryFetch = async (getLink, options, isOpenSeaJS = false) => {
                let success = false;
                let retryCounter = 0;
                return new Promise(async (resolve, reject) => {
                    if (isOpenSeaJS) {
                        // Keep retrying fetch until successful
                        while (!success) {
                            try {
                                let data = null;
                                if (getLink === GET_ASSET) {
                                    data = await seaport.api.getAsset(options);
                                } else if (getLink === GET_ORDERS) {
                                    data = await seaport.api.getOrders(options);
                                }
                                success = true;
                                await delay(rateLimitDelay);
                                resolve(data);
                                return data;
                            } catch (e) {
                                console.error("Error getting API data: ", e)
                                retryCounter++;
                                if (retryCounter >= maxFetchRetries) {
                                    setFailedToFetch(true);
                                    failFlag = true;
                                    console.error("Reached max retries: ", maxFetchRetries, " Setting fail state to true and returning");
                                    resolve(false);
                                    return false;
                                }        
                                await delay(rateLimitDelay);
                            }
                        }
                    } else {
                        while (!success) {
                            try {
                                let res = await axios.get(getLink, options);
                                let data = res.data;
                                console.log("Fetched API data: ", data);
                                success = true;
                                await delay(rateLimitDelay);
                                resolve(data);
                                return data;
                            } catch (e) {
                                console.error("Error getting API data: ", e);
                                retryCounter++;
                                if (retryCounter >= maxFetchRetries) {
                                    setFailedToFetch(true);
                                    failFlag = true;
                                    console.error("Reached max retries: ", maxFetchRetries, " Setting fail state to true and returning");
                                    resolve(false);
                                    return false;
                                }                    
                                await delay(rateLimitDelay);
                            }
                        }    
                    }
                })
            }


            // tokenId = 4175; // Test ID

            // --- Get Asset ---
            let openSeaAssetData = null;
            
            // If max fetch retries not yet reached:
            if (!failedToFetch && !failFlag) {
                // Fetch
                openSeaAssetData = await retryFetch(GET_ASSET, {tokenAddress, tokenId}, true);

                setOpenSeaAsset(openSeaAssetData);
    
                console.log("Asset: ", openSeaAssetData);
            }

            // --- Get Listings ---

           
            const listingsOptions = { method: 'GET', headers: { Accept: 'application/json', 'X-API-KEY': OpenSeaApiKey } };

            let listingsData = null;

            // If max fetch retries not yet reached:
            if (!failedToFetch && !failFlag) {
                // Fetch
                listingsData = await retryFetch(`https://api.opensea.io/api/v1/asset/${tokenAddress}/${tokenId}/listings?limit=20`, listingsOptions)  
                listingsData = listingsData.listings;
                console.log("Listings: ", listingsData);
                if (listingsData && listingsData.length > 0) {
                    // Set Sale state to least expensive listing
                    setSale(listingsData.reduce((a, b) => {
                        let aUsdPrice = ((a?.current_price * a?.payment_token_contract?.usd_price) / (10 ** a?.payment_token_contract?.decimals));
                        let bUsdPrice = ((b?.current_price * b?.payment_token_contract?.usd_price) / (10 ** b?.payment_token_contract?.decimals));
                        if (aUsdPrice < bUsdPrice) {
                            return a;
                        } else {
                            return b;
                        }
                    }))
                    setListings(listingsData);
                }     
            }

            // -- Get Offers --

            //Get offers (bids), a.k.a. orders where `side == 0`
            let orders, count = null;

            // If max fetch retries not yet reached:
            if (!failedToFetch && !failFlag) {
                // Fetch
                ({orders, count} = await retryFetch(
                    GET_ORDERS, 
                    {            
                        asset_contract_address: tokenAddress,
                        token_id: tokenId,
                        side: OrderSide.Buy,
                        limit: 50
                    },
                    true
                ))
                setOrders(orders.sort((a, b) => new Date(a.expirationTime - b.expirationTime)));
                console.log("Offers orders: ", orders);
                console.log("Offers count: ", count);
            }

            // -- Get Stats --

            // NOTE: Necessary to get stats separately since floor_price is currently bugged 
            // (always set to 0) when retrieving collection stats from an asset.
            // Can remove this unnecessary call when that is fixed. See opensea-js issue #465

            const statsOptions = { method: 'GET', headers: { Accept: 'application/json', 'X-API-KEY': OpenSeaApiKey } };

            let statsData = null;
            
            // If max fetch retries not yet reached:
            if (!failedToFetch && !failFlag) {
                //Fetch
                statsData = await retryFetch(`https://api.opensea.io/api/v1/collection/${openSeaAssetData?.collection?.slug}/stats`, statsOptions)
                statsData = statsData.stats;
                setStats(statsData);
    
                console.log("Stats: ", statsData);
            }

            // -- Get Sales --

            var fetchedEvents = [];

            const eventsOptions = {
                method: 'GET',
                headers: { Accept: 'application/json', 'X-API-KEY': OpenSeaApiKey }
            };

            let salesData = null;

            // If max fetch retries not yet reached:
            if (!failedToFetch && !failFlag) {
                // Fetch
                salesData = await retryFetch(`https://api.opensea.io/api/v1/events?token_id=${tokenId}&asset_contract_address=${tokenAddress}&event_type=successful`, eventsOptions);
                salesData = salesData.asset_events;
                console.log("Sales: ", salesData);
                if (salesData && salesData.length > 0) {
                    fetchedEvents.push(...salesData); 
                }            
            }
           
            // -- Get Transfers --
            let transfersData = null;

            // If max fetch retries not yet reached:
            if (!failedToFetch && !failFlag) {
                // Fetch
                transfersData = await retryFetch(`https://api.opensea.io/api/v1/events?token_id=${tokenId}&asset_contract_address=${tokenAddress}&event_type=transfer`, eventsOptions)
                transfersData = transfersData.asset_events;
                console.log("Transfers: ", transfersData);
                if (transfersData && transfersData.length > 0) {
                    fetchedEvents.push(...transfersData); 
                }
    
                // Sort fetched events so in descending order of recency
                fetchedEvents.sort((a, b) => new Date(b.created_date) - new Date(a.created_date))
                console.log("fetchedEvents: ", fetchedEvents);
                setEvents(fetchedEvents);
    
                console.log("Sale: ", sale);
            }

            setLoadingMessage("Loading OpenSea info...");
            setIsLoader(false); 
        }

        // Only fetch OpenSeaAPI if hashID exists, 
        // API is still loading (has not already been fetched),
        // and fail flag is false
        if (data.hashID && isLoader && !failedToFetch) {
            fetchOpenSeaAPI(data.hashID);
        }
    
    }, [data]);

    /**
     * Returns Accordion component based on passed accordion summary, details,
     * and expanded bool
     * 
     * @param summary - Component to be placed within AccordionSummary
     * @param details - Component to be placed within AccordionDetails
     * @param expanded - If true, accordion will always be expanded with no icon.
     * Otherwise allow accordion to expand/retract with icon displayed
     * @returns Accordion component
     */
    const SimpleAccordion = ({summary, details, expanded}) => {
        return (
            <Accordion expanded={expanded}>
                <AccordionSummary expandIcon={expanded ? null : <ExpandMoreIcon />} >
                    <Typography>{summary}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {details}
                </AccordionDetails>
            </Accordion>
        );
    }


    /**
     * Returns TableContainer component based on passed cellLabels, tableRows,
     * rowCondition, and emptyMsg parameters
     * 
     * @param cellLabels - Array of header label strings for each column
     * @param tableRows - Table rows to be placed within TableBody
     * @param rowCondition - If true, table will show table rows. Else, will show empty message
     * @param emptyMsg - Message to show if rowCondition is false
     * @returns TableContainer component
     */
     const SimpleTable = ({cellLabels, tableRows, rowCondition = true, emptyMsg = "No Items Found"}) => {
        return (
            <TableContainer sx={{ maxHeight: 200 }} component={Paper}>
                <Table aria-label="simple table" size="small">
                    <TableHead>
                        <TableRow>
                            {cellLabels.map((value, index) => (
                                <TableCell key={index}>{value}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    {rowCondition ? (
                        <TableBody>
                            {tableRows}
                        </TableBody>
                    ) : (
                        <TableBody>
                            <TableRow
                                key={1}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>{emptyMsg}</TableCell>
                            </TableRow>
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
        );
    }


    return (
        <div>
            <OpenSeaDragonViewer image={MyImageData} title={title} />
            <Container maxWidth="1g">
                <div id="ImageData">
                    <p id="Title">{data.name}</p>
                    {openSeaAsset && !isLoader && (
                        <Typography paragraph variant={"subtitle2"}>
                            {"Owned by "}
                            <a href={`https://opensea.io/${openSeaAsset?.owner?.user?.username ?? openSeaAsset?.owner?.address}`}>
                                {openSeaAsset?.owner?.user?.username
                                    ?? openSeaAsset?.owner?.address?.slice(2, 8)?.toUpperCase()
                                    ?? ""}
                            </a>
                        </Typography>
                    )}
                    <Typography>{descriptionText}</Typography>
                    <Typography paragraph display="block" style={{ marginTop: 15 }}>
                        <a id="Title" style={{fontWeight: "normal"}}href={`${openSeaRedirect}${data.hashID}`}>
                            {openSeaLink}
                        </a>
                    </Typography>
                    <Typography paragraph display="block"> {imageLinkNotification}
                        <a href={`Json_images_original/${title}.png`} download>
                            click here to download.
                        </a>
                    </Typography>
                    <Typography> 
                        {"Image Hash: " + data.image_hash}
                    </Typography>
                    <Typography paragraph display="block"> 
                        {"Token ID: " + data.hashID}
                    </Typography>
                    <Typography paragraph>
                        <a href={`JsonData/${data.image_hash}`} download>
                            Download Hash File
                        </a>
                    </Typography>
                    {isLoader && !failedToFetch && (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginBottom: 15, marginTop: 15 }}>
                            <GridSpinner size={30} color="#686769" loading={true}></GridSpinner>
                            <Typography style={{ marginTop: 15, padding: 5, color: 'white', backgroundColor: '#686769', maxWidth: '50%' }}>{loadingMessage}</Typography>
                        </div>
                    )}
                    {openSeaAsset && !isLoader && !failedToFetch && (
                        <div style={{ display: 'flex', whiteSpace: 'pre-wrap', justifyContent: 'center', alignItems: 'center', textAlign: 'center', marginTop: 15 }}>
                            <div style={{ maxWidth: 600 }}>
                                {sale && (
                                    <SimpleAccordion 
                                        summary={"Sale ends " + new Date(sale.expiration_time * 1000).toLocaleString()}
                                        details={                                            
                                            <Typography>
                                                {"Current Price: " + sale.current_price / (10 **
                                                sale.payment_token_contract?.decimals)
                                                + " " + sale.payment_token_contract?.symbol
                                                + " ("
                                                + '$' + ((sale.current_price * sale.payment_token_contract?.usd_price) / (10 ** sale.payment_token_contract?.decimals))
                                                    .toLocaleString(undefined, { 'minimumFractionDigits': 2, 'maximumFractionDigits': 2 })
                                                + ")"}
                                            </Typography>
                                        }
                                        expanded
                                    />
                                )}
                                <SimpleAccordion 
                                    summary={"Description"}
                                    details={
                                        <Typography>
                                            {openSeaAsset.description ?? "Created by " + 
                                            openSeaAsset.assetContract?.name}
                                        </Typography>
                                    }
                                    expanded
                                />
                                {openSeaAsset?.traits?.length > 0 && (
                                    <SimpleAccordion 
                                        summary={"Properties"}
                                        details={
                                            <Typography>
                                                {openSeaAsset?.traits?.map((trait) => `${trait.trait_type}: ${trait.value}\n`)
                                                    .join('') + "\n"}
                                            </Typography>
                                        }
                                    />
                                )}
                                <SimpleAccordion 
                                    summary={"About " + openSeaAsset?.collection?.name}
                                    details={<Typography>{openSeaAsset.collection?.description}</Typography>}
                                />
                                <SimpleAccordion
                                    summary={"Details"}
                                    details={
                                        <Typography style={{ overflowWrap: 'break-word' }}>
                                            {"Contact Address: " + openSeaAsset?.tokenAddress + "\n"}
                                            {"Token ID: " + openSeaAsset?.tokenId + "\n"}
                                            {"Token Standard: " + openSeaAsset?.assetContract?.schemaName + "\n\n"}
                                        </Typography>
                                    }
                                />
                                {'\n'}
                                <SimpleAccordion 
                                    summary={"Listings"}
                                    details={
                                        <SimpleTable
                                            cellLabels={["Price", "USD Price", "Expiration", "From"]} 
                                            tableRows={listings?.map((listing) => (
                                                <TableRow
                                                    key={listing.order_hash}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {listing.current_price / (10 ** listing.payment_token_contract?.decimals)
                                                            + " " + listing.payment_token_contract?.symbol}
                                                    </TableCell>
                                                    <TableCell>
                                                        {'$' + ((listing.current_price * listing.payment_token_contract?.usd_price) / (10 ** listing.payment_token_contract?.decimals))
                                                            .toLocaleString(undefined, { 'minimumFractionDigits': 2, 'maximumFractionDigits': 2 })}
                                                    </TableCell>
                                                    <TableCell>
                                                        {new Date(listing.expiration_time * 1000).toLocaleDateString('en-US')}
                                                    </TableCell>
                                                    <TableCell>
                                                        {openSeaAsset?.owner?.user?.username
                                                            ?? openSeaAsset?.owner?.address?.slice(2, 8)?.toUpperCase()
                                                            ?? ""}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                            rowCondition={(listings && listings.length > 0) ? true : false}
                                            emptyMsg={"No Listings Found"}
                                        />
                                    }
                                />
                                {'\n'}
                                <SimpleAccordion 
                                    summary={"Offers"}
                                    details={
                                        <SimpleTable
                                            cellLabels={["Price", "Floor Difference", "USD Price", "Expiration", "From"]} 
                                            tableRows={orders?.map((order) => {
                                                let usdCurrentPrice = ((order.currentPrice * order.paymentTokenContract?.usdPrice)
                                                    / (10 ** order.paymentTokenContract?.decimals));
                                                let usdFloorPrice = stats ? (stats.floor_price * openSeaAsset?.collection?.paymentTokens[0]?.usdPrice) : "";
                                                let floorDiff = ((parseFloat(usdCurrentPrice) / parseFloat(usdFloorPrice)) - 1).toFixed(2);

                                                return (
                                                    <TableRow
                                                        key={order.hash}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {order.currentPrice / (10 ** order.paymentTokenContract?.decimals)
                                                                + " " + order.paymentTokenContract?.symbol}
                                                        </TableCell>
                                                        <TableCell>
                                                            {stats && (floorDiff >= 0 ? Math.abs(floorDiff) * 100 + "% above"
                                                                : Math.abs(floorDiff) * 100 + "% below")}
                                                        </TableCell>
                                                        <TableCell>
                                                            {'$' + usdCurrentPrice
                                                                .toLocaleString(undefined, { 'minimumFractionDigits': 2, 'maximumFractionDigits': 2 })}
                                                        </TableCell>
                                                        <TableCell>
                                                            {new Date(order.expirationTime * 1000).toLocaleDateString('en-US')}
                                                        </TableCell>
                                                        <TableCell>
                                                            {/* Show user address since username not given in API call*/}
                                                            {order.makerAccount?.address?.slice(2, 8)?.toUpperCase() ?? ""}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                            rowCondition={(orders && orders.length > 0) ? true : false}
                                            emptyMsg={"No Offers Found"}
                                        />
                                    }
                                />
                                {'\n'}
                                <SimpleAccordion 
                                    summary={"Item Activity"}
                                    details={
                                        <SimpleTable
                                            cellLabels={["Event", "Price", "From", "To", "Date"]} 
                                            tableRows={events?.map((event) => (
                                                <TableRow
                                                    key={event.id}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    {event.event_type === "successful" ? (
                                                        <>
                                                            <TableCell component="th" scope="row">
                                                                {"Sale"}
                                                            </TableCell>
                                                            <TableCell>
                                                                {event.total_price ? (event.total_price / (10 ** event.payment_token?.decimals) + " " + event.payment_token?.symbol) : ""}
                                                            </TableCell>
                                                            <TableCell>
                                                                {/* Show username, if not available show address*/}
                                                                {event.seller?.user?.username ? event.seller?.user?.username : event.seller?.address
                                                                    ? event.seller.address.slice(2, 8).toUpperCase() : ""}
                                                            </TableCell>
                                                            <TableCell>
                                                                {event.winner_account?.user?.username ? event.winner_account?.user?.username : event.winner_account?.address
                                                                    ? event.winner_account.address.slice(2, 8).toUpperCase() : ""}
                                                            </TableCell>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <TableCell component="th" scope="row">
                                                                {"Transfer"}
                                                            </TableCell>
                                                            <TableCell>
                                                                {event.total_price ? (event.total_price / (10 ** event.payment_token?.decimals) + " " + event.payment_token?.symbol) : ""}
                                                            </TableCell>
                                                            <TableCell>
                                                                {event.from_account?.user?.username ? event.from_account?.user?.username : event.from_account?.address
                                                                    ? event.from_account.address.slice(2, 8).toUpperCase() : ""}
                                                            </TableCell>
                                                            <TableCell>
                                                                {event.to_account?.user?.username ? event.to_account?.user?.username : event.to_account?.address
                                                                    ? event.to_account.address.slice(2, 8).toUpperCase() : ""}
                                                            </TableCell>
                                                        </>
                                                    )}
                                                    <TableCell>
                                                        {new Date(event.created_date).toLocaleDateString('en-US')}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                            rowCondition={(events && events.length > 0) ? true : false}
                                            emptyMsg={"No Item Activity Found"}
                                        />
                                    }
                                />
                                {'\n'}
                            </div>
                        </div>
                    )}
                </div>
            </Container>
        </div>
    );
}
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var TxContext = /** @class */ (function () {
    function TxContext(gasPrice, origin) {
        this.gasPrice = gasPrice;
        this.origin = origin;
    }
    return TxContext;
}());
exports.default = TxContext;
